import React from 'react';
import { ERoutes } from 'src/config/enums';
import { EOxConsultationVariant, OxConsultation } from 'src/components/OxConsultation';
import { graphql, useStaticQuery } from 'gatsby';

export const BookAnAppointment = (): JSX.Element => {
    const data = useStaticQuery(graphql`
        query {
            drSabikaMobileImage: file(
                relativePath: { eq: "doctors/Doctor-DrSabika-Mobile-1100x800.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            drSabikaDesktopImage: file(
                relativePath: { eq: "doctors/Doctor-DrSabika-Desktop-960x870.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    `);

    const images = [
        {
            ...data.drSabikaMobileImage.childImageSharp.gatsbyImageData,
            media: `(max-width: 767px)`
        },
        {
            ...data.drSabikaDesktopImage.childImageSharp.gatsbyImageData,
            media: `(min-width: 768px)`
        }
    ];

    return (
        <OxConsultation
            label=""
            title="Book a consultation"
            paragraph={[
                'Step into your power.',
                'Book a consultation for a truly transformative experience.'
            ]}
            linkUrl={ERoutes.Appointment}
            buttonTitle="BOOK NOW"
            fluidImages={images}
            variant={EOxConsultationVariant.Alternative}
        />
    );
};
